import { instance } from './instance'
import { FAQType, ProfileType } from '../types/types'

export const faqAPI = {
  getInstructionList() {
    return instance.get<string[]>(`/api/get-instruction-list`).then((res) => res.data)
  },
  getFAQList() {
    return instance.get<FAQType[]>(`/api/get-faq-list`).then((res) => res.data)
  },
  sendQuestion(theme: string, self: string) {
    return instance.post<string>(`/api/send-question`, { theme, self }).then((res) => res.data)
  },
  insertFAQ(question: string, answer: string, locale: string) {
    return instance.post<string>('/api/insert-faq', { question, answer, locale }).then((res) => res.data)
  },
}
