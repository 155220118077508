import React, { useRef, useState, Fragment, useEffect, useContext } from 'react'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  IconButton,
  Divider,
  TextField,
  Switch,
  Stack,
  Select,
  MenuItem,
  FormControl,
  ListSubheader,
  InputLabel,
  InputAdornment,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { AppStateType } from '../../../../redux/store'
import { ReactComponent as CloseSearch } from '../../../../assets/icon/group/close_search_user.svg'
import { getAllUsers } from '../../../../redux/reducers/groups_reducer'
import SearchIcon from '@mui/icons-material/Search'
import { UsersType } from '../../../../types/types'
import { GridRowsProp } from '@mui/x-data-grid'
import { instance } from '../../../../api/instance'
import { LanguageContext, translate } from '../../../../constants/lang'
import { CHAT_TYPE_AUTHORITY, CHAT_TYPE_PRESS, CHAT_TYPE_SOCIAL_NETWORK } from '../../../../constants/chat_constant'
import { debounce } from '../../../../utils/debounce'

type ListUsersPropsType = {
  checkedUsers: string[]
  checkedParticipants: Array<any>
  checkedUsersRole: any
  setCheckedUsers: (checkedUsers: string[]) => void
  setCheckedUsersRole: (checkedUsersRole: any) => void
  drillId: string
  page: number
  setPage: (page: number) => void
  userslist: UsersType[]
  searchUsers: (event: React.ChangeEvent<HTMLInputElement>) => void
  isExpert: boolean
}

const ListUsers: React.FC<ListUsersPropsType> = (props) => {
  let checkedUsers = props.checkedUsers
  let setCheckedUsers = props.setCheckedUsers
  let checkedParticipants = props.checkedParticipants
  let setCheckedUsersRole = props.setCheckedUsersRole
  let [users, setUsers] = useState<UsersType[]>([])

  const valueSearch = useRef<HTMLInputElement>()
  let dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getAllUsers(props.page))
  }, [props.page])
  React.useEffect(() => {
    const userslistNew = props.userslist.map((user) => {
      return checkedParticipants.find((participant) => participant.login === user.login) || user
    })
    setUsers(userslistNew)
  }, [props.userslist])

  const handleChangeUser = (event: any, estudiante: any) => {
    const index = checkedUsers.indexOf(estudiante.login)
    if (event.name === 'isChecked') {
      estudiante.isChecked = event.checked
    }

    // The checked value is altered before the state changes for some reason is not a trully controlled component
    // So the next conditions are INVERTED.
    //@ts-ignore
    if (event.checked) return setCheckedUsers((prevstate) => [...prevstate, estudiante.login])

    if (!event.checked && index > -1) {
      //@ts-ignore
      return setCheckedUsers((prevstate) => {
        prevstate.splice(index, 1)
        setCheckedUsersRole((prevstate: any) => {
          let newState = prevstate.filter((i: any) => i.login !== estudiante.login)
          return JSON.parse(JSON.stringify(newState)) // Here's the trick => React does not update the f* state array changes even with the spread operator, the reference is still the same.
        })
        return JSON.parse(JSON.stringify(prevstate)) // Here's the trick => React does not update the f* state array changes even with the spread operator, the reference is still the same.
      })
    }
  }

  const handleChangeUserRole = (event: any, estudiante: any) => {
    let roleId: string | undefined
    const isChecked = event.checked
    const currentLogin = estudiante.login

    // Определяем значения roleId и leader для полей
    if (event.name === 'roleName') {
      roleId = event.value
    } else if (event.name === 'leader') {
      estudiante.leader = isChecked
    }

    setCheckedUsersRole((prevstate: any) => {
      // Создаем копию состояния
      let updatedUsers = [...prevstate]
      // Находим индекс текущего пользователя
      const userIndex = updatedUsers.findIndex((user: any) => user.login === currentLogin)

      if (userIndex !== -1) {
        // Обновление существующего пользователя
        if (event.name === 'roleName') {
          updatedUsers[userIndex] = {
            ...updatedUsers[userIndex],
            login: currentLogin,
            roleId,
            leader: estudiante.leader || false,
          }
        } else if (event.name === 'leader') {
          updatedUsers[userIndex].leader = isChecked
        } else if (event.name === 'expert' && props.isExpert) {
          // Здесь вместо удаления объекта просто обновляем флаг expert
          updatedUsers[userIndex].expert = isChecked
        }
      } else {
        // Добавление нового пользователя, с условным добавлением expert, если необходимо
        const newUser = {
          login: currentLogin,
          roleId: roleId || '',
          leader: estudiante.leader || false,
          ...(event.name === 'expert' && isChecked ? { expert: true } : null),
        }
        updatedUsers.push(newUser)
      }
      return updatedUsers
    })
  }

  const { language } = useContext(LanguageContext)

  const [roleData, setRoleData] = useState<GridRowsProp>([])
  const [filteredRoleData, setFilteredRoleData] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState<any>('')
  const getRoleList = () => {
    instance.get<GridRowsProp>('/api/role-api').then((res) => {
      setRoleData(res.data)
      setFilteredRoleData(res.data)
    })
  }

  const searchRole = (event: any) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    let filteredRole = roleData.filter((i: any) => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
    setFilteredRoleData(filteredRole)
  }, [searchTerm])

  useEffect(() => {
    getRoleList()
  }, [])

  const getUserRole = (login: string): string => {
    const user = props.checkedUsersRole.find((user: UsersType) => user.login === login)
    return user ? user.roleId : null
  }

  return (
    <Fragment>
      <Grid style={style.box}>
        <Box style={style.parentOver}>
          <Box style={style.parent}>
            <Grid>
              <Grid sx={style.user}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid>
                    <Grid container>
                      <Grid>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                      <Grid sx={{ pt: 0.5, width: '300px' }}>
                        <TextField
                          placeholder={translate('enterSearchWord', language)}
                          fullWidth
                          inputRef={valueSearch}
                          onChange={props.searchUsers}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*Childrens*/}
            <Grid>
              {checkedUsers &&
                users.map((estudiante, index) => (
                  <Grid key={estudiante.login + index}>
                    <Divider variant="fullWidth" />
                    <Grid sx={style.user} container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                          <Checkbox
                            key={estudiante.login}
                            checked={checkedUsers.includes(estudiante.login)}
                            name="isChecked"
                            onChange={(event) => handleChangeUser(event.target, estudiante)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Grid sx={style.userinfo}>
                            <Typography sx={style.fio}>{estudiante.alias}</Typography>
                            <Typography sx={style.info}>{estudiante.lang && estudiante.lang}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid sx={style.email}>{estudiante.mail}</Grid>
                    </Grid>
                    {estudiante.isChecked && (
                      <FormControl
                        variant="filled"
                        fullWidth
                        sx={{ flexDirection: 'row', justifyContent: 'space-around', mt: 1, mb: 2 }}
                      >
                        <InputLabel id="demo-simple-select-label1" sx={{ ml: 6 }}>
                          {translate('selectDefaultRole', language)}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          id="demo-simple-select"
                          name="roleName"
                          defaultValue={getUserRole(estudiante.login)}
                          label="role"
                          onChange={(event) => {
                            handleChangeUserRole(event.target, estudiante)
                          }}
                          onOpen={() => {
                            setFilteredRoleData(roleData)
                          }}
                          disableUnderline
                          MenuProps={MenuProps}
                          sx={{
                            borderRadius: 4,
                            backgroundColor: '#EFF0F6',
                            width: '300px',
                          }}
                        >
                          <ListSubheader>
                            <TextField
                              size="small"
                              autoFocus
                              placeholder={translate('enterSearchWord', language)}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={searchRole}
                              onKeyDown={(e) => {
                                if (e.key !== 'Escape') {
                                  e.stopPropagation()
                                }
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                  '& > fieldset': {
                                    borderWidth: 1,
                                    borderColor: '#A4B3C6',
                                  },
                                },
                              }}
                            />
                          </ListSubheader>
                          {filteredRoleData.length > 0 &&
                            filteredRoleData.map((role: any) => (
                              <MenuItem key={role.id} value={role.id}>
                                <Grid>
                                  <Typography>{role.name}</Typography>
                                  <Typography sx={style.role}>
                                    {role.socialRoleType.$type === CHAT_TYPE_AUTHORITY &&
                                      translate('pressService', language)}
                                    {role.socialRoleType.$type === CHAT_TYPE_PRESS && translate('massMedia', language)}
                                    {role.socialRoleType.$type === CHAT_TYPE_SOCIAL_NETWORK &&
                                      translate('socialNetworks', language)}
                                  </Typography>
                                </Grid>
                              </MenuItem>
                            ))}
                        </Select>
                        <Grid>
                          <Stack direction="row" alignItems="center">
                            <Switch
                              checked={estudiante.leader}
                              name="leader"
                              onChange={(event) => {
                                handleChangeUserRole(event.target, estudiante)
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography>{translate('leader', language)}</Typography>
                          </Stack>
                          {props.isExpert && (
                            <Stack direction="row" alignItems="center">
                              <Switch
                                defaultChecked={estudiante.expert}
                                name="expert"
                                onChange={(event) => {
                                  handleChangeUserRole(event.target, estudiante)
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <Typography>{translate('expert', language)}</Typography>
                            </Stack>
                          )}
                        </Grid>
                      </FormControl>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  )
}

export default ListUsers

const ITEM_HEIGHT = 68
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const style = {
  box: {
    // width: 768,
    maxHeight: 717,
    // bgcolor: 'white',
    border: '1px solid #E5E5EB',
    borderRadius: 16,
    padding: '9px 0px',
  },
  parent: {
    pl: 4,
    pr: 4,
    maxHeight: 517,
    overflow: 'overlay',
  },
  // Second overflow to work in Chrome and FF
  parentOver: {
    overflow: 'auto',
  },
  userlist: {
    maxHeight: 500,
  },
  user: {
    pl: 4,
    pr: 4,
    mt: 1.3,
    mb: 1.3,
  },
  fio: {
    fontWeight: 400,
    fontSize: 17,
    lineHeight: '160%',
  },
  info: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '160%',
    color: '#A4B3C6',
  },
  email: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '160%',
    color: '#A4B3C6',
  },
  selectall: {
    pt: 1,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '160%',
    color: '#14142B',
  },
  userinfo: {
    width: 250,
  },
  role: {
    fontSize: 12,
    color: '#A4B3C6',
  },
  search: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#F7F7FC', borderRadius: 8 },
  },
}
