import { instance } from './instance'
import { ProfileType, RespType, UsersType } from '../types/types'

type getUsersType = {
  rows: ProfileType[]
  total: number
}

export const UsersAPI = {
  getUsers(page: number = 1) {
    return instance.get<getUsersType>(`api/user-api?page=${page}`).then((res) => res.data)
  },
  addUser(userData: UsersType[]) {
    return instance.post<RespType>(`/api/user-api`, userData).then((res) => res.data)
  },
  editUser(userData: UsersType[]) {
    return instance.put<null>(`/api/user-api`, userData).then((res) => res.data)
  },
  deleteUser(users: string[]) {
    return instance.delete<string>('/api/users-api', { data: users }).then((res) => res.data)
  },
  getUserRoleMap(login: string) {
    return instance.get<Array<any>>(`api/user-role-map-api/${login}`).then((res) => res.data)
  },
  saveUserRoleMap(login: string, roleUserArray: Array<any>) {
    return instance.post<string>(`/api/user-role-map-api/` + login, roleUserArray).then((res) => res.data)
  },
  getDefaultRole(login: string) {
    return instance.get<any>(`api/default-api/${login}`).then((res) => res.data)
  },
  addDefaultRole(defaultRole: string[], login: string) {
    return instance.post<RespType>(`/api/default-api/${login}`, defaultRole).then((res) => res.data)
  },
  editDefaultRole(defaultRole: string[], login: string) {
    return instance.put<RespType>(`/api/default-api/${login}`, defaultRole).then((res) => res.data)
  },
  deleteDefaultRole(login: string) {
    return instance.delete<string>(`/api/default-api/${login}`).then((res) => res.data)
  },
  changePass(str: string, pass: string) {
    return instance.post<string>('/api/ch-p/', { str: str, pass: pass }).then((res) => res.data)
  },
  preloadAvatar(file: File, login: string) {
    let formData = new FormData()
    formData.append('file', file)
    return instance
      .post<string>(`/api/profile-avatar-preload-by-login/${login}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
  },
  saveAvatar(login: string, config: any) {
    return instance.post(`/api/profile-avatar-crop/${login}`, config).then((res) => res.data)
  },
  deleteAvatar(contentType: string, fileName: string, login: string) {
    return instance.post(`/api/profile-avatar/${login}`, { contentType, fileName }).then((res) => res.data)
  },
}
