import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { roleType } from '../../types/types'
import { RolesAPI } from '../../api/roles'
const SET_ROLES = 'SET_ROLES'
const SET_ROLES_ERROR = 'SET_ROLES_ERROR'
const SET_ROLE_ERROR = 'SET_ROLE_ERROR'
const SET_LOAD = 'SET_LOAD'
const SET_AVATAR_NAME = 'SET_AVATAR_NAME'

let initialstate = {
  roles: [] as any,
  error: false as boolean,
  errorRole: '' as string,
  isLoad: null as null | boolean,
  avatarLink: null as null | string,
}

export type initialStateType = typeof initialstate

const roles_reducer = (state = initialstate, action: ActionsType): initialStateType => {
  switch (action.type) {
    case SET_ROLES: {
      return { ...state, roles: action.roles }
    }
    case SET_ROLES_ERROR: {
      return { ...state, error: true }
    }
    case SET_ROLE_ERROR: {
      return { ...state, errorRole: action.errorRole }
    }
    case SET_LOAD: {
      return { ...state, isLoad: action.status }
    }
    case SET_AVATAR_NAME: {
      return { ...state, avatarLink: action.fileName }
    }
    default:
      return state
  }
}

export default roles_reducer

type ActionsType = setRolesType | setRolesTypeError | setRoleTypeError | setLoad | setAvatarType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type setRolesType = {
  type: typeof SET_ROLES
  roles: Array<roleType>
}

type setRolesTypeError = {
  type: typeof SET_ROLES_ERROR
  error: boolean
}

type setRoleTypeError = {
  type: typeof SET_ROLE_ERROR
  errorRole: string
}

export let setRoles = (roles: Array<roleType>): setRolesType => {
  return {
    type: SET_ROLES,
    roles,
  }
}

export let setRolesError = (error: boolean): setRolesTypeError => {
  return {
    type: SET_ROLES_ERROR,
    error,
  }
}

export let setRoleError = (errorRole: string): setRoleTypeError => {
  return {
    type: SET_ROLE_ERROR,
    errorRole,
  }
}

export let getAllRoles = (): ThunkType => async (dispatch: DispatchType) => {
  try {
    let rolesData = await RolesAPI.getRoles()
    dispatch(setRoles(rolesData))
  } catch (e: any) {
    console.log('Error:', JSON.stringify(e.response.data, null, 4))
    dispatch(setRolesError(true))
  }
}

export let addRole =
  (name: string, socialRoleType: any): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let response = await RolesAPI.addRoles(name, socialRoleType)
      if (response.id) {
        let rolesData = await RolesAPI.getRoles()
        dispatch(setRoles(rolesData))
      } else {
        dispatch(setRoleError('Произошла ошибка добавления роли'))
      }
    } catch (e: any) {
      dispatch(setRoleError('Произошла ошибка добавления роли'))
    }
  }

export let editRole =
  (id: string, name: string, socialRoleType: any, avatar: string | null): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let response = await RolesAPI.editRoles(id, name, socialRoleType, avatar)
      let rolesData = await RolesAPI.getRoles()
      dispatch(setRoles(rolesData))
    } catch (e: any) {
      dispatch(setRoleError('Произошла ошибка редактирования роли'))
    }
  }

export let removeRole =
  (roles: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let response = await RolesAPI.deleteRoles(roles)
      let rolesData = await RolesAPI.getRoles()
      dispatch(setRoles(rolesData))
    } catch (e: any) {
      dispatch(setRoleError('Произошла ошибка удаления роли'))
    }
  }

type setLoad = {
  type: typeof SET_LOAD
  status: boolean
}

export let setLoader = (status: boolean): setLoad => {
  return {
    type: SET_LOAD,
    status,
  }
}

export let getFile =
  (roleName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await RolesAPI.getFile(roleName)
    console.log(resp)
    if (resp == 'File uploaded') dispatch(setLoader(true))
  }

export let preloadFile =
  (file: File, roleName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let resp = await RolesAPI.preloadFile(file)
      if (resp.name) {
        dispatch(setLoader(true))
        console.log(resp.name)
        dispatch(setAvatarName(resp.name?.split('/').pop() ?? null))
      } else {
        dispatch(setRoleError('Произошла ошибка загрузки аватара'))
      }
    } catch (e: any) {
      dispatch(setRoleError('Произошла ошибка загрузки аватара'))
    }
  }

type setAvatarType = {
  type: typeof SET_AVATAR_NAME
  fileName: string | null
}

// Изменено на объект ключ-значение
export const setAvatarName = (fileName: string | null): setAvatarType => {
  return {
    type: SET_AVATAR_NAME,
    fileName,
  }
}

export let fileSave =
  (roleName: string, config: any): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      await RolesAPI.fileSave(roleName, config)
      let rolesData = await RolesAPI.getRoles()
      dispatch(setRoles(rolesData))
      dispatch(setLoader(false))
    } catch (e: any) {
      dispatch(setRoleError('Произошла ошибка сохранения аватара'))
    }
  }

export let deleteFile =
  (contentType: string, fileName: string, roleName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    await RolesAPI.deleteFile(contentType, fileName, roleName)
    dispatch(setLoader(false))
  }
