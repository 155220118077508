import { instance } from './instance'
import { RespType, roleType } from '../types/types'

type socialRoleTypeType = {
  $type: string
}

export const RolesAPI = {
  getRoles() {
    return instance.get<Array<roleType>>(`/api/role-api`).then((res) => res.data)
  },
  addRoles(name: string, socialRoleType: socialRoleTypeType) {
    return instance.post<RespType>(`/api/role-api`, { name, socialRoleType }).then((res) => res.data)
  },
  editRoles(id: string, name: string, socialRoleType: socialRoleTypeType, avatar: string | null) {
    return instance.put<string>(`/api/role-api`, { id, name, socialRoleType, avatar }).then((res) => res.data)
  },
  deleteRoles(roles: string[]) {
    return instance.delete<string>('/api/role-api', { data: roles }).then((res) => res.data)
  },
  getFile(roleName: string) {
    return instance.get(`/api/social-role-avatar/${roleName}`).then((res) => res.data)
  },
  preloadFile(file: File) {
    let formData = new FormData()
    formData.append('files', file)
    return instance
      .post<{ name: string }>('/api/file/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
  },
  fileSave(roleName: string, config: any) {
    return instance.post(`/api/social-role-avatar-save/${roleName}`, config).then((res) => res.data)
  },
  deleteFile(contentType: string, fileName: string, roleName: string) {
    return instance.post(`/api/social-role-avatar/${roleName}`, { contentType, fileName }).then((res) => res.data)
  },
}
