import React, { FC, useContext, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, Snackbar, Alert } from '@mui/material'
import { theme } from './theme/theme'
import { Login } from './components/login/login'
import { Header } from './components/header/header'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from './redux/store'
import { actions, login } from './redux/reducers/auth_reducer'
import { getUserProfile } from './redux/reducers/profile_reducer'
import { LanguageContext } from './constants/lang'
import { clearError } from './redux/reducers/error_reducer'
import NotificationSnackbar from './components/BasicComponent/NotificationSnackbar'

const App: FC = (props) => {
  const isLogged = useSelector((store: AppStateType) => store.Auth.isAuth)
  const dispatch = useDispatch()
  const notification = useSelector((state: AppStateType) => state.Error)
  const { changeLanguage } = useContext(LanguageContext)
  const { children } = props

  const user = sessionStorage.getItem('store')
  const lang = sessionStorage.getItem('lang')

  if (lang) {
    changeLanguage(lang)
  }

  const parsedUser = user ? JSON.parse(user) : null

  useEffect(() => {
    if (localStorage.getItem('store') !== null) {
      localStorage.removeItem('store')
    }

    if (parsedUser !== null) {
      if (parsedUser.Auth.isAuth) {
        dispatch(actions.setAuthUserData(parsedUser.Auth.username, parsedUser.Auth.lang, parsedUser.Auth.isAuth))
        dispatch(getUserProfile())
      }
    }
  }, [dispatch, parsedUser])

  const handleCloseSnackbar = () => {
    dispatch(clearError())
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {isLogged ? (
          <>
            <Header />
            {React.Children.map(children, (child) => child)}
          </>
        ) : (
          <Login />
        )}
        <NotificationSnackbar />
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
