import React, { FC } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../redux/store'
import { clearError } from '../../redux/reducers/error_reducer'

const NotificationSnackbar: FC = () => {
  const dispatch = useDispatch()
  const notification = useSelector((state: AppStateType) => state.Error)

  const handleClose = () => {
    dispatch(clearError())
  }

  return (
    <Snackbar
      open={Boolean(notification.message) && notification.message !== ''}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.isSuccess ? 'success' : 'error'}
        variant="filled"
        sx={{
          width: '100%',
          backgroundColor: notification.isSuccess ? '#4caf50' : '#f44336',
          color: '#fff',
        }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationSnackbar
