// prettier-ignore
export function debounce(func, delay) {
  let timeoutId
  return function(...args) {
    // Пробел после "function"
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
