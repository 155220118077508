import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { accomulatePermissionsType, GroupType, UsersType } from '../../types/types'
import { GroupAPI } from '../../api/group'
const SET_USERS_SELECT_GROUP = 'SET_USERS_SELECT_GROUP'
const SET_GROUPS = 'SET_GROUPS'
const SET_ACCOMULATE_PERMISSIONS = 'SET_ACCOMULATE_PERMISSIONS'
const SET_ALL_USERS = 'SET_ALL_USERS'

let initialstate = {
  groups: [] as Array<GroupType>,
  allUsers: [] as Array<UsersType>,
  totalUser: 0,
  sizeUsersPage: 7,
  usersSelectGroup: [] as Array<UsersType>,
}

export type InitialstateType = typeof initialstate

const groups_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_GROUPS: {
      return { ...state, groups: action.groups }
    }
    case SET_ALL_USERS: {
      return { ...state, allUsers: action.users, totalUser: action.total }
    }
    case SET_USERS_SELECT_GROUP: {
      return { ...state, usersSelectGroup: action.users }
    }
    default:
      return state
  }
}

export default groups_reducer

type ActionsType = SetRightsType | setAllUsers | setUsersSelectGroup

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetRightsType = {
  type: typeof SET_GROUPS
  groups: Array<GroupType>
}

export let setGroups = (groups: Array<GroupType>): SetRightsType => {
  return {
    type: SET_GROUPS,
    groups,
  }
}

export let getGroups = (): ThunkType => async (dispatch: DispatchType) => {
  let groupsData = await GroupAPI.getGroups()
  dispatch(setGroups(groupsData))
}

type setAccomulatePermissionsType = {
  type: typeof SET_ACCOMULATE_PERMISSIONS
  permissions: accomulatePermissionsType
  i: number
}

type setAllUsers = {
  type: typeof SET_ALL_USERS
  users: Array<UsersType>
  total: number
}

export let setAllUsers = (users: Array<UsersType>, total: number): setAllUsers => {
  return {
    type: SET_ALL_USERS,
    users,
    total,
  }
}

export let getAllUsers =
  (page?: number, text?: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let usersData = await GroupAPI.getAllUsers(page, text)
    dispatch(setAllUsers(usersData.rows, usersData.total))
  }

// создание новой группы
export let addGroup =
  (name: string, description: string, listLogins: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    let response = await GroupAPI.addGroup(name, description, listLogins)
    if (response.id) {
      let groupsData = await GroupAPI.getGroups()
      dispatch(setGroups(groupsData))
    }
  }

//редактирование группы
export let editGroup =
  (id: string, name: string, description: string, listLogins: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let response = await GroupAPI.editGroup(id, name, description, listLogins)
      let groupsData = await GroupAPI.getGroups()
      dispatch(setGroups(groupsData))
    } catch (error) {
      console.error('Error editing group:', error)
      // Можешь добавить обработку ошибки здесь, например, показать уведомление
    }
  }
type setUsersSelectGroup = {
  type: typeof SET_USERS_SELECT_GROUP
  users: Array<UsersType>
}

export let setUsersSelectGroup = (users: Array<UsersType>): setUsersSelectGroup => {
  return {
    type: SET_USERS_SELECT_GROUP,
    users,
  }
}

export let getUsersSelectGroup =
  (id: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let usersGroup = await GroupAPI.getInfoAboutSelectGroup(id)
    dispatch(setUsersSelectGroup(usersGroup))
  }

export let removeGroup =
  (groups: string[]): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      let response = await GroupAPI.deleteGroups(groups)
      let groupsData = await GroupAPI.getGroups()
      dispatch(setGroups(groupsData))
    } catch (e) {
      console.error('Error editing group:', e)
    }
  }

export let sendMail =
  (id: string, subject: string, content: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let response = await GroupAPI.sendMail(id, subject, content)
  }
