import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import thunkMiddleware from 'redux-thunk'
import auth_reducer from './reducers/auth_reducer'
import profile_reducer from './reducers/profile_reducer'
import drills_reducer from './reducers/drills_reducer'
import users_reducer from './reducers/users_reducer'
import rights_reducer from './reducers/rights_reducer'
import { profile } from 'console'
import groups_reducer from './reducers/groups_reducer'
import roles_reducer from './reducers/roles_reducer'
import chat_reducer from './reducers/chat_reducer'
import faq_reducer from './reducers/faq_reducer'
import error_reducer from './reducers/error_reducer'

type PropertyType<T> = T extends { [key: string]: infer U } ? U : never

export type InferActionsType<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertyType<T>>

export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

let RootReducers = combineReducers({
  Auth: auth_reducer,
  Profile: profile_reducer,
  Drills: drills_reducer,
  Users: users_reducer,
  Rights: rights_reducer,
  Groups: groups_reducer,
  Roles: roles_reducer,
  Chat: chat_reducer,
  FAQ: faq_reducer,
  Error: error_reducer,
})

type RootReducerType = typeof RootReducers

export type AppStateType = ReturnType<RootReducerType>

//@ts-ignore
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(RootReducers, /* preloadedState, */ composeEnhancers(
//     applyMiddleware(thunkMiddleware)
//   ));

let store = createStore(RootReducers, applyMiddleware(thunkMiddleware))

store.subscribe(() => {
  sessionStorage['store'] = JSON.stringify(store.getState())
})

export default store
