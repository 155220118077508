import React, { FC, useContext, useState } from 'react'
import { Box, Button, Typography, FormControl, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import { ModalUnstyled } from '@mui/base'
import { styled } from '@mui/system'
import { useDispatch } from 'react-redux'
import { setChatSettings as updateChatSettings } from '../../../redux/reducers/chat_reducer'
import { LanguageContext, translate } from '../../../constants/lang'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  box: {
    width: 792,
    maxHeight: 751,
    bgcolor: 'white',
    border: '0px solid #000',
    borderRadius: 6,
    overflow: 'auto',
  },
  info: {
    padding: '24px 44px 12px 44px',
    overflow: 'auto',
    maxHeight: 717,
  },
  title: {
    fontWeight: 800,
    fontSize: 49,
    mb: 2,
  },
  secondaryTitle: {
    fontWeight: '800',
    fontSize: '20px',
    color: 'black',
    ml: 5.7,
  },
  button: {
    m: 5,
  },
  boxBorder: {
    border: '1px solid',
    borderColor: '#E5E5EB',
    borderRadius: '24px',
    padding: '1%',
    m: 5,
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '17px',
  },
}

type ChatSettingsTypes = {
  openChatSettings: () => void
  closeChatSettings: () => void
  isOpenChatSettings: boolean
}

export const ChatSettings: FC<ChatSettingsTypes> = (props) => {
  const dispatch = useDispatch()
  const { language } = useContext(LanguageContext)
  const [chatSettings, setChatSettings] = useState({
    AUTHORITY: true,
    PRESS: true,
    NETWORK: true,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatSettings({
      ...chatSettings,
      [event.target.name]: event.target.checked,
    })
  }

  const setNewChatSettings = () => {
    dispatch(updateChatSettings(chatSettings))
    props.closeChatSettings()
  }

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenChatSettings}
        onClose={props.closeChatSettings}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <Box sx={style.info}>
            <Typography sx={style.title}>{translate('settings', language)}</Typography>
          </Box>
          <Typography marginBottom="3%" sx={style.secondaryTitle}>
            {translate('showChats', language)}
          </Typography>
          <Box height="28%" sx={style.boxBorder}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={chatSettings.AUTHORITY} onChange={handleChange} name="AUTHORITY" />}
                  label={translate('pressService', language)}
                />
                <FormControlLabel
                  control={<Checkbox checked={chatSettings.PRESS} onChange={handleChange} name="PRESS" />}
                  label={translate('massMedia', language)}
                />
                <FormControlLabel
                  control={<Checkbox checked={chatSettings.NETWORK} onChange={handleChange} name="NETWORK" />}
                  label={translate('socialNetworks', language)}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={style.button}>
            <Button onClick={setNewChatSettings} variant="contained" size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('apply', language)}
            </Button>
            <Button onClick={props.closeChatSettings} size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('close', language)}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  )
}
