import { instance } from './instance'
import { GroupType, RespType, UsersType } from '../types/types'

export const GroupAPI = {
  getGroups() {
    return instance.get<Array<GroupType>>(`/api/groups-list`).then((res) => res.data)
  },
  getAllUsers(page: number = 1, text?: string) {
    return instance
      .get<{ rows: UsersType[]; total: number }>(
        `/api/user-api?page=${page}&sizePerPage=7&sortField=alias&sortOrder=asc${
          text ? `&textFilter=${encodeURIComponent(text)}` : ''
        }`,
      )
      .then((res) => res.data)
  },
  addGroup(name: string, description: string, listLogins: string[]) {
    return instance
      .post<RespType>(`/api/groups-api`, { group: { name, description }, listLogins })
      .then((res) => res.data)
  },
  getInfoAboutSelectGroup(groupID: string) {
    return instance.get<UsersType[]>(`/api/groups-users/` + groupID).then((res) => res.data)
  },
  editGroup(id: string, name: string, description: string, listLogins: string[]) {
    return instance
      .put<string>(`/api/groups-api`, { group: { id, name, description }, listLogins })
      .then((res) => res.data)
  },
  deleteGroups(groups: string[]) {
    return instance.delete<string>('/api/groups-api', { data: groups }).then((res) => res.data)
  },
  sendMail(id: string, subject: string, content: string) {
    return instance.post<string>('/api/groups-mail', { id, subject, content }).then((res) => res.data)
  },
}
