import { instance } from './instance'
import { DrillsType, RespType, UsersType } from '../types/types'
import { ParticipantsType } from '../types/types'

export const DrillsAPI = {
  getDrills() {
    return instance.get<Array<DrillsType>>(`api/drills`).then((res) => {
      res.data.forEach((i) => {
        !i.withAgreement && Object.assign(i, (i.withAgreement = false))
      })
      res.data.sort((a, b) => b.start - a.start)
      return res.data
    })
  },
  getDrill(drillId: string) {
    return instance.get<DrillsType>(`api/drill-api/` + drillId).then((res) => res.data)
  },
  addDrills(name: string, description: string[], start: number, end: number, withAgreement: boolean, expert: boolean) {
    return instance
      .post<RespType>(`/api/drill-api`, { name, start, end, withAgreement, expert })
      .then((res) => res.data)
  },
  addDrillsGroup(
    name: string,
    description: string[],
    start: number,
    end: number,
    withAgreement: boolean,
    groupId: string,
    expert: boolean,
  ) {
    return instance
      .post<RespType>(`/api/drill-api/` + groupId, { name, start, end, withAgreement, expert })
      .then((res) => res.data)
  },
  addDrillsTeam(name: string, start: number, end: number, withAgreement: boolean, teamId: string, expert: boolean) {
    return instance
      .post<string>(`/api/drill-api-fromdrill/` + teamId, { name, start, end, withAgreement, expert })
      .then((res) => res.data)
  },
  editDrills(id: string, name: string, start: number, end: number, withAgreement: boolean) {
    return instance.put<string>(`/api/drill-api`, { id, name, start, end, withAgreement }).then((res) => res.data)
  },
  deleteDrills(drillId: string) {
    return instance.delete<string>('/api/drill-api/' + drillId).then((res) => res.data)
  },
  getParticipants(drillId: string, page: number = 1, text?: string) {
    return instance
      .get<{ rows: UsersType[]; total: number }>(
        `/api/team-api/${drillId}?page=${page}&sizePerPage=100&sortField=alias&sortOrder=asc${
          text ? `&textFilter=${encodeURIComponent(text)}` : ''
        }`,
      )
      .then((res) => res.data)
  },
  setParticipants(userRoles: Array<any>, id: string) {
    return instance.put<Array<ParticipantsType>>(`/api/team-list-api/` + id, userRoles).then((res) => res.data)
  },
  getExpert(drilllId: string) {
    return instance.get<any>(`/api/team-expert/` + drilllId).then((res) => res.data)
  },
  updateExpert(usersListId: string[], expertId: string, drilllId: string) {
    const data = [
      {
        expertId: expertId,
        userIds: usersListId,
      },
    ]
    return instance.put<Array<ParticipantsType>>(`/api/team-expert/` + drilllId, data).then((res) => res.data)
  },
  expertMark(messageId: string, mark: number | null) {
    let data = {}
    if (Number.isInteger(mark)) {
      data = {
        mark: mark,
      }
    }
    return instance.post<any>(`api/chat/expertMark/` + messageId, data).then((res) => res.data)
  },
}
