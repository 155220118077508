// errorReducer.js
import message from '../../assets/icon/chat/message'

const initialState = {
  message: '',
  isOpen: false,
  isSuccess: false,
}

export type setErrorType = {
  type: string
  message: string
  isSuccess: boolean
}

export const setError = (message: string, isSuccess: boolean): setErrorType => ({
  type: 'SET_ERROR',
  message: message,
  isSuccess: isSuccess,
})

export const clearError = () => ({
  type: 'CLEAR_ERROR',
})

type ActionType = setErrorType

export type InitialstateType = typeof initialState

const error_reducer = (state = initialState, action: ActionType): InitialstateType => {
  switch (action.type) {
    case 'SET_ERROR':
      return { message: action.message, isOpen: true, isSuccess: action.isSuccess }
    case 'CLEAR_ERROR':
      return { message: initialState.message, isOpen: false, isSuccess: initialState.isSuccess }
    default:
      return state
  }
}

export default error_reducer
