import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import {
  responseEmojiType,
  FetchEmojiType,
  PermissionsType,
  EventsTypeResp,
  EventsTypeReq,
  emosType,
  Emos,
  rolesForExpertAssessmentType,
  ListUserRelatedToExpert,
  ExpertsWithRoles,
  ExpertWithRolesArrayItem,
  MarkType,
  messageMark,
} from '../../types/types'
import { setDrill, setDrills, SetDrillsType, SetDrillType } from './drills_reducer'
import { ChatAPI } from '../../api/chat'
import { Participant } from '../../types/chat-api-types'
import { countLikes } from '../../utils/countLikes'
import { DrillsAPI } from '../../api/drills'
const SET_MESSAGES = 'SET_MESSAGES'
const ADD_MESSAGE = 'ADD_MESSAGE'
const EDIT_MESSAGE = 'EDIT_MESSAGE'
const SET_PERMISSIONS = 'SET_PERMISSIONS'
const SET_EMOS = 'SET_EMOS'
const SET_LOAD = 'SET_LOAD'
const SET_USER_ROLES = 'SET_USER_ROLES'
const SET_CHAT_SETTINGS = 'SET_CHAT_SETTINGS'
const ADD_EMOJI = 'ADD_EMOJI'
const SET_NAME_DRILL = 'SET_NAME_DRILL'
const WAS_READED_GENERAL_CHAT = 'WAS_READED_GENERAL_CHAT'
const SET_STATUS_LEADER = 'SET_STATUS_LEADER'
const SET_ERROR = 'SET_ERROR'
const SET_STATUS_DRILL = 'SET_STATUS_DRILL'
const SET_MAP_ROLES = 'SET_MAP_ROLES'
const SET_MAP_AVATAR = 'SET_MAP_AVATAR'
const SET_FILE_NAME = 'SET_FILE_NAME'
const SET_LIST_ID_EMIOJI = 'SET_LIST_ID_EMIOJI'
const SET_LEADER_LIST = 'SET_LEADER_LIST'
const SET_EXPERT_DRILL_STATUS = 'SET_EXPERT_DRILL_STATUS'
const SET_EXPERTS_WITH_ROLES = 'SET_EXPERTS_WITH_ROLES'
const SET_STATUS_EXPERT = 'SET_STATUS_EXPERT'
const SET_MARKS = 'SET_MARKS'
const ADD_MARK = 'ADD_MARK'

let initialstate = {
  isActive: null as null | boolean,
  withAgreement: null as null | boolean,
  nameDrill: null as null | string,
  messages: [] as EventsTypeResp[],
  permissions: [] as PermissionsType[],
  emos: {} as Emos | {},
  isLoad: null as null | boolean,
  isLeader: false,
  isExpert: false,
  nameRole: null as null | string,
  socialRoleType: null as null | string,
  leaderList: {} as { [key: string]: boolean },
  chatSettings: {
    AUTHORITY: true,
    PRESS: true,
    NETWORK: true,
  } as any,
  wasReadedGeneralChat: true,
  isError: false,
  roles: {} as { [key: string]: string },
  avatars: {} as { [key: string]: string },
  responseFileName: null as null | string,
  drillWithExpert: false,
  listUsersForExpert: [] as ExpertsWithRoles[],
  markList: [] as MarkType[],
}

export type InitialstateType = typeof initialstate

const chat_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_MESSAGES: {
      return { ...state, messages: action.messages }
    }
    case SET_STATUS_DRILL: {
      return { ...state, isActive: action.isActive }
    }
    case ADD_MESSAGE: {
      let newArray = [...state.messages] //создаем новый массив
      let id = newArray.findIndex((x) => x.id === action.message.id)
      if (id != -1) {
        newArray[id] = action.message
      } else {
        newArray = [...state.messages, action.message]
      }
      return { ...state, messages: newArray }
    }
    case EDIT_MESSAGE: {
      const newArray = [...state.messages] //создаем новый массив
      let id = newArray.findIndex((x) => x.id === action.message.id)
      newArray[id] = action.message
      return { ...state, messages: newArray }
    }
    case SET_PERMISSIONS: {
      return { ...state, permissions: action.permissions }
    }
    case SET_EMOS: {
      return { ...state, emos: action.emos }
    }
    case SET_LOAD: {
      return { ...state, isLoad: action.status }
    }
    case SET_USER_ROLES: {
      return { ...state, isLeader: action.isLeader, nameRole: action.nameRole, socialRoleType: action.socialRoleType }
    }
    case SET_CHAT_SETTINGS: {
      return { ...state, chatSettings: action.chatSettings }
    }
    case SET_NAME_DRILL: {
      return { ...state, nameDrill: action.name, withAgreement: action.withAgreement }
    }
    case SET_STATUS_LEADER: {
      return { ...state, isLeader: action.isLeader }
    }
    case SET_STATUS_EXPERT: {
      return { ...state, isExpert: action.isExpert }
    }
    case ADD_EMOJI: {
      let updatedEmos = { ...state.emos }

      if (updatedEmos[action.emoji.id]) {
        const emojiData = updatedEmos[action.emoji.id]

        if (action.emoji.emo === '👍') {
          const isLikedByMember = emojiData.Like.users.includes(action.emoji.member)
          const isDislikedByMember = emojiData.Dislike.users.includes(action.emoji.member)

          if (isLikedByMember) {
            // Если пользователь уже поставил лайк, снимаем его
            emojiData.Like.count = Math.max(0, emojiData.Like.count - 1)
            emojiData.Like.users = emojiData.Like.users.filter((user) => user !== action.emoji.member)
            if (action.emoji.member === action.login) emojiData.Like.isMyReaction = false
          } else {
            // Если стоит дизлайк от этого пользователя, сначала снимаем его
            if (isDislikedByMember) {
              emojiData.Dislike.count = Math.max(0, emojiData.Dislike.count - 1)
              emojiData.Dislike.users = emojiData.Dislike.users.filter((user) => user !== action.emoji.member)
              if (action.emoji.member === action.login) emojiData.Dislike.isMyReaction = false
            }
            // Теперь добавляем лайк
            emojiData.Like.count++
            emojiData.Like.users.push(action.emoji.member)
            if (action.emoji.member === action.login) emojiData.Like.isMyReaction = true
          }
        } else if (action.emoji.emo === '👎') {
          const isLikedByMember = emojiData.Like.users.includes(action.emoji.member)
          const isDislikedByMember = emojiData.Dislike.users.includes(action.emoji.member)

          if (isDislikedByMember) {
            // Если пользователь уже поставил дизлайк, снимаем его
            emojiData.Dislike.count = Math.max(0, emojiData.Dislike.count - 1)
            emojiData.Dislike.users = emojiData.Dislike.users.filter((user) => user !== action.emoji.member)
            if (action.emoji.member === action.login) emojiData.Dislike.isMyReaction = false
          } else {
            // Если стоит лайк от этого пользователя, сначала снимаем его
            if (isLikedByMember) {
              emojiData.Like.count = Math.max(0, emojiData.Like.count - 1)
              emojiData.Like.users = emojiData.Like.users.filter((user) => user !== action.emoji.member)
              if (action.emoji.member === action.login) emojiData.Like.isMyReaction = false
            }
            // Теперь добавляем дизлайк
            emojiData.Dislike.count++
            emojiData.Dislike.users.push(action.emoji.member)
            if (action.emoji.member === action.login) emojiData.Dislike.isMyReaction = true
          }
        }
      } else {
        const newEmoji = countLikes(action.emoji.chatMessageEmoDTOList, action.login)
        updatedEmos = { ...updatedEmos, ...newEmoji }
      }

      return {
        ...state,
        emos: updatedEmos,
      }
    }

    case WAS_READED_GENERAL_CHAT: {
      return { ...state, wasReadedGeneralChat: action.wasReaded }
    }
    case SET_ERROR: {
      return { ...state, isError: action.error }
    }
    case SET_MAP_ROLES: {
      return { ...state, roles: action.roles }
    }
    case SET_MAP_AVATAR: {
      return { ...state, avatars: action.avatars }
    }
    case SET_FILE_NAME: {
      return { ...state, responseFileName: action.fileName }
    }
    case SET_LEADER_LIST: {
      return { ...state, leaderList: action.leaderList }
    }
    case SET_EXPERT_DRILL_STATUS: {
      return { ...state, drillWithExpert: action.drillWithExpert }
    }
    case SET_EXPERTS_WITH_ROLES: {
      return { ...state, listUsersForExpert: action.expertsWithUsersRole }
    }
    case SET_MARKS: {
      return { ...state, markList: action.marksList }
    }
    case ADD_MARK: {
      const { mark, id, member, markList } = action.mark

      // Если в сообщении есть свойство `mark`, значит, добавлена или изменена оценка.
      if (mark !== undefined) {
        // Проверяем, существует ли уже оценка для данного пользователя (member) и сообщения (id).
        const existingMarkIndex = state.markList.findIndex((item) => item.messageId === id && item.userId === member)

        if (existingMarkIndex >= 0) {
          // Если оценка существует, заменяем её на новую.
          const updatedMarkList = [...state.markList]
          updatedMarkList[existingMarkIndex] = { messageId: id, userId: member, mark }
          return {
            ...state,
            markList: updatedMarkList,
          }
        } else {
          // Если оценка ещё не была добавлена, добавляем новый элемент.
          return {
            ...state,
            markList: [...state.markList, { messageId: id, userId: member, mark }],
          }
        }
      } else {
        // Если свойства `mark` нет, значит, оценка удалена.
        return {
          ...state,
          markList: state.markList.filter((item) => item.userId !== member || item.messageId !== id),
        }
      }
    }

    default:
      return state
  }
}

export default chat_reducer

type ActionsType =
  | SetMessagesType
  | addMessage
  | editMessage
  | SetPermissionsType
  | SetEmojiType
  | setLoad
  | setUserRoleType
  | setChatSettingsType
  | SetDrillType
  | SetDrillsType
  | addEmoji
  | SetNameDrill
  | wasReadGeneralChat
  | setNameLeader
  | setErrorFileType
  | setStatusDrill
  | setRolesType
  | setAvatarType
  | setFileType
  | setLeaderListType
  | setExpertDrillStatus
  | setExpertsAndUsersType
  | setExpert
  | setMarksType
  | addMarkType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetMessagesType = {
  type: typeof SET_MESSAGES
  messages: EventsTypeResp[]
}

type SetPermissionsType = {
  type: typeof SET_PERMISSIONS
  permissions: PermissionsType[]
}

type SetEmojiType = {
  type: typeof SET_EMOS
  emos: Emos
}

type SetNameDrill = {
  type: typeof SET_NAME_DRILL
  name: string | null
  withAgreement: boolean
}

export let setMessages = (messages: EventsTypeResp[]): SetMessagesType => {
  return {
    type: SET_MESSAGES,
    messages,
  }
}

export let setPermissions = (permissions: PermissionsType[]): SetPermissionsType => {
  return {
    type: SET_PERMISSIONS,
    permissions,
  }
}

export let setEmoji = (emos: Emos): SetEmojiType => {
  return {
    type: SET_EMOS,
    emos,
  }
}

export let setNameDrill = (name: string | null, withAgreement: boolean): SetNameDrill => {
  return {
    type: SET_NAME_DRILL,
    name: name,
    withAgreement,
  }
}

type setChatSettingsType = {
  type: typeof SET_CHAT_SETTINGS
  chatSettings: any
}

type setStatusDrill = {
  type: typeof SET_STATUS_DRILL
  isActive: boolean
}

type setExpertDrillStatus = {
  type: typeof SET_EXPERT_DRILL_STATUS
  drillWithExpert: boolean
}

export let setChatSettings = (chatSettings: any): setChatSettingsType => {
  return {
    type: SET_CHAT_SETTINGS,
    chatSettings,
  }
}

export let setUserRole = (isLeader: boolean, socialRoleType: string, nameRole: string): setUserRoleType => {
  return {
    type: SET_USER_ROLES,
    isLeader,
    socialRoleType,
    nameRole,
  }
}

export let setDrillStatus = (isActive: boolean): setStatusDrill => {
  return {
    type: SET_STATUS_DRILL,
    isActive,
  }
}

export let setExpertDrillStatus = (drillWithExpert: boolean): setExpertDrillStatus => {
  return {
    type: SET_EXPERT_DRILL_STATUS,
    drillWithExpert,
  }
}

type setUserRoleType = {
  type: typeof SET_USER_ROLES
  isLeader: boolean
  socialRoleType: string
  nameRole: string
}

export let getMessages =
  (sessionID: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let messagesData = await ChatAPI.getMessages(sessionID)

    'events' in messagesData ? dispatch(setMessages(messagesData.events)) : dispatch(setMessages([]))
    dispatch(setDrill(messagesData.drill))
    'permissions' in messagesData ? dispatch(setPermissions(messagesData.permissions)) : dispatch(setMessages([]))
    //'emos' in messagesData ? dispatch(setEmoji(messagesData.emos)) : dispatch(setEmoji([]))
    if ('emos' in messagesData) {
      dispatch(setEmoji(countLikes(messagesData.emos, messagesData.user.login)))
    }
    'isActive' in messagesData ? dispatch(setDrillStatus(messagesData.isActive)) : dispatch(setDrillStatus(false))
    if (messagesData.drill.expert) {
      const drillWithExperts = messagesData.drill.expert || false
      dispatch(setExpertDrillStatus(messagesData.drill.expert))
      let experts = await DrillsAPI.getExpert(messagesData.drill.id)
      dispatch(setExpertsAndUsers(experts))
      dispatch(setMarks(messagesData.markList))
    }
    messagesData.role
      ? dispatch(
          setUserRole(
            'otherRole' in messagesData ? true : false,
            messagesData.role.socialRoleType.$type,
            messagesData.role.name,
          ),
        )
      : dispatch(setUserRole('otherRole' in messagesData ? true : false, 'expert', 'expert'))
    dispatch(
      setNameDrill(
        messagesData.drill.name,
        'withAgreement' in messagesData.drill ? messagesData.drill.withAgreement : false,
      ),
    )
    let foundParticipant = messagesData.participants.find(
      (participant) => participant.login === messagesData.user.login,
    )

    let isLeader = foundParticipant ? foundParticipant.leader : false
    let isExpert = foundParticipant ? foundParticipant.expert : false

    dispatch(setLeaderStatus(isLeader))
    messagesData.drill.expert ? dispatch(setExpertStatus(isExpert)) : null

    const leaderList = messagesData.participants.reduce((acc: { [key: string]: boolean }, user) => {
      if (user.leader) {
        acc[user.socialRole.name] = true
      }
      return acc
    }, {} as { [key: string]: boolean })
    dispatch(setLeaderList(leaderList))
  }

export let sendMessages =
  (message: EventsTypeReq): ThunkType =>
  async (dispatch: DispatchType) => {
    let messagesData = await ChatAPI.sendMessage(message)
  }

export let setLeaderStatus = (isLeader: boolean): setNameLeader => {
  return {
    type: SET_STATUS_LEADER,
    isLeader,
  }
}

type setNameLeader = {
  type: typeof SET_STATUS_LEADER
  isLeader: boolean
}

export let setExpertStatus = (isExpert: boolean): setExpert => {
  return {
    type: SET_STATUS_EXPERT,
    isExpert,
  }
}

type setExpert = {
  type: typeof SET_STATUS_EXPERT
  isExpert: boolean
}

type addMessage = {
  type: typeof ADD_MESSAGE
  message: EventsTypeResp
}

export let addMessage = (message: EventsTypeResp): addMessage => {
  return {
    type: ADD_MESSAGE,
    message,
  }
}

type editMessage = {
  type: typeof EDIT_MESSAGE
  message: EventsTypeResp
}

export let editMessage = (message: EventsTypeResp): editMessage => {
  return {
    type: EDIT_MESSAGE,
    message,
  }
}

export let preloadFile =
  (file: File): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      dispatch(setLoader(false))
      let resp = await ChatAPI.preloadFile(file)
      if (resp.name) {
        dispatch(setLoader(true))
        dispatch(setFileName(resp.name))
      }
    } catch (error) {
      dispatch(setErrorFile(true))
      dispatch(setLoader(true))
    }
  }

type setLoad = {
  type: typeof SET_LOAD
  status: boolean | null
}

export let setLoader = (status: boolean | null): setLoad => {
  return {
    type: SET_LOAD,
    status,
  }
}

export let deleteFile =
  (contentType: string, fileName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await ChatAPI.deleteFile(contentType, fileName)
    dispatch(setLoader(null))
  }

export let fileSave =
  (file: string, contentType: string, fileName: string, wsChannel: WebSocket | null): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await ChatAPI.fileSave(file, contentType, fileName)
    dispatch(setLoader(null))
  }

type addEmoji = {
  type: typeof ADD_EMOJI
  emoji: responseEmojiType
  login: string
}

export let addEmoji = (emoji: responseEmojiType, login: string): addEmoji => {
  return {
    type: ADD_EMOJI,
    emoji,
    login,
  }
}

type addMarkType = {
  type: typeof ADD_MARK
  mark: messageMark
}

export let addMark = (mark: messageMark): addMarkType => {
  return {
    type: ADD_MARK,
    mark,
  }
}

type wasReadGeneralChat = {
  type: typeof WAS_READED_GENERAL_CHAT
  wasReaded: boolean
}

export let wasReadGeneralChat = (wasReaded: boolean): wasReadGeneralChat => {
  return {
    type: WAS_READED_GENERAL_CHAT,
    wasReaded,
  }
}

type setErrorFileType = {
  type: typeof SET_ERROR
  error: boolean
}

export let setErrorFile = (error: boolean): setErrorFileType => {
  return {
    type: SET_ERROR,
    error,
  }
}

type setRolesType = {
  type: typeof SET_MAP_ROLES
  roles: { [key: string]: string }
}

// Изменено на объект ключ-значение
export const setRoles = (roles: { [key: string]: string }): setRolesType => {
  return {
    type: SET_MAP_ROLES,
    roles,
  }
}

type setAvatarType = {
  type: typeof SET_MAP_AVATAR
  avatars: { [key: string]: string }
}

// Изменено на объект ключ-значение
export const setAvatar = (avatars: { [key: string]: string }): setAvatarType => {
  return {
    type: SET_MAP_AVATAR,
    avatars,
  }
}

type setFileType = {
  type: typeof SET_FILE_NAME
  fileName: string | null
}

// Изменено на объект ключ-значение
export const setFileName = (fileName: string | null): setFileType => {
  return {
    type: SET_FILE_NAME,
    fileName,
  }
}

type setLeaderListType = {
  type: typeof SET_LEADER_LIST
  leaderList: { [key: string]: boolean }
}

// Изменено на объект ключ-значение
export const setLeaderList = (leaderList: { [key: string]: boolean }): setLeaderListType => {
  return {
    type: SET_LEADER_LIST,
    leaderList,
  }
}

export let setExperts =
  (idDrill: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await DrillsAPI.getExpert(idDrill)
  }

type setExpertsAndUsersType = {
  type: typeof SET_EXPERTS_WITH_ROLES
  expertsWithUsersRole: ExpertsWithRoles[]
}

export const setExpertsAndUsers = (expertsWithUsersRole: ExpertsWithRoles[]): setExpertsAndUsersType => {
  return {
    type: SET_EXPERTS_WITH_ROLES,
    expertsWithUsersRole,
  }
}

type setMarksType = {
  type: typeof SET_MARKS
  marksList: MarkType[]
}

export const setMarks = (marksList: MarkType[]): setMarksType => {
  return {
    type: SET_MARKS,
    marksList,
  }
}
