import * as React from 'react'
import { Box, Button, Modal, Typography, Grid } from '@mui/material'
import pick from '../../assets/img/1.jpg'
import { styled } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import { ProfileInformation } from './information/ProfileInformation'
import { ReactComponent as Change_photo } from '../../assets/icon/profile/change_photo.svg'
import { ReactComponent as Change_password } from '../../assets/icon/profile/change_password.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../redux/store'
import InputData from './information/InputData'
import { updateProfile } from '../../redux/reducers/profile_reducer'
import { actions, updatePassword } from '../../redux/reducers/auth_reducer'
import { LanguageContext, translate } from '../../constants/lang'
import { useContext } from 'react'

type PersonalProfilePropsTypes = {
  openUserProfile: () => void
  closeUserProfile: () => void
  isOpenUserProfile: boolean
}

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  header: {
    fontWeight: 800,
    fontSize: 40,
  },
  box: {
    width: 768,
    bgcolor: 'white',
    border: '0px solid #000',
    m: '2%',
    borderRadius: 6,
    overflow: 'auto',
  },
  info: {
    ml: 8,
    mb: '6%',
    h: '100px',
    pt: '8%',
    overflow: 'auto',
  },
}

export const PersonalProfile: React.FC<PersonalProfilePropsTypes> = (props) => {
  let [organization, setOrganization] = React.useState('')
  let [position, setPositionOpt] = React.useState('')
  let ProfileInfo = useSelector((store: AppStateType) => store.Profile.profile)
  let Login = useSelector((store: AppStateType) => store.Auth.username)
  let lang = useSelector((store: AppStateType) => store.Auth.lang)
  let organizationOpt = useSelector((store: AppStateType) => store.Profile.profile?.organizationOpt)
  let positionOpt = useSelector((store: AppStateType) => store.Profile.profile?.positionOpt[0])
  const { language } = useContext(LanguageContext)

  let changeOrganizationOpt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganization(event.target.value)
  }
  let changePositionOpt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionOpt(event.target.value)
  }

  React.useEffect(() => {
    setOrganization(organizationOpt!)
    setPositionOpt(positionOpt!)
  }, [organizationOpt])

  let dispatch = useDispatch()
  let updateUserProfle = () => {
    dispatch(
      updateProfile(
        Login!,
        ProfileInfo!.alias,
        ProfileInfo!.fio,
        ProfileInfo!.email,
        'ru',
        organization,
        Array(position!),
      ),
    )
    props.closeUserProfile()
  }

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenUserProfile}
        onClose={props.closeUserProfile}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <Box sx={style.info}>
            <Grid sx={style.header}>{translate('personalAccount', language)}</Grid>
            <ProfileInformation title={translate('labelEmail', language)} descr={ProfileInfo?.email} />
            <ProfileInformation title={translate('labelAlias', language)} descr={ProfileInfo?.alias} />
            <ProfileInformation
              title={translate('labelLanguage', language)}
              descr={lang! == 'RU' ? '🇷🇺 Русский' : '🏴󠁧󠁢󠁥󠁮󠁧󠁿English'}
            />
            {organizationOpt && (
              <ProfileInformation title={translate('organizationTitle', language)} descr={organizationOpt} />
            )}
            <Box sx={{ marginTop: '48px' }}>
              <Grid justifyContent="space-between" container sx={{ paddingRight: '64px' }}>
                <Grid>
                  <Button onClick={updateUserProfle} variant="contained" size="large">
                    {translate('labelSave', language)}
                  </Button>
                  <Button onClick={props.closeUserProfile} size="large">
                    {translate('close', language)}
                  </Button>
                </Grid>
                <Grid>
                  <ChangePassword />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </StyledModal>
    </div>
  )
}

//компонент второго модального окна для смены пароля
function ChangePassword() {
  let dispatch = useDispatch()
  let messageError = useSelector((store: AppStateType) => store.Auth.updatePasswordMessageError)
  let [oldPassword, setOldPassword] = React.useState('')
  let [newPassword, setNewPassword] = React.useState('')
  let [repeatNewPassword, setRepeatNewPassword] = React.useState('')
  const { language } = useContext(LanguageContext)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let inputOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.currentTarget.value)
  }
  let inputNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.currentTarget.value)
  }
  let inputRepeatPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatNewPassword(event.currentTarget.value)
  }

  const reviseNewPassword = () => {
    if (newPassword != repeatNewPassword) dispatch(actions.updatePasswordMessageError('Новые пароли не совпадают!'))
    else dispatch(updatePassword(oldPassword, newPassword))
  }

  return (
    <React.Fragment>
      <Button onClick={handleOpen} size="large" startIcon={<Change_password />} sx={{ backgroundColor: '#E6EFF6' }}>
        {translate('changePassword', language)}
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...styles.box }}>
          <Typography sx={{ ...styles.papertext }} variant="h6" component="h6">
            {translate('changePassword', language)}
          </Typography>
          <Grid sx={{ ...styles.inputs }}>
            <Box>
              <Grid>
                <InputData changeInput={inputOldPassword} label={'Введите старый пароль'} type={'password'} />
              </Grid>
              <Grid sx={{ marginTop: '24px' }}>
                <InputData changeInput={inputNewPassword} label={'Введите новый пароль'} type={'password'} />
              </Grid>
              <Grid sx={{ marginTop: '24px' }}>
                <InputData changeInput={inputRepeatPassword} label={'Повторите новый пароль'} type={'password'} />
              </Grid>
            </Box>
          </Grid>
          <Button variant="contained" onClick={reviseNewPassword} size="large">
            {translate('labelSave', language)}
          </Button>
          <Button onClick={handleClose} size="large">
            {translate('close', language)}
          </Button>
          {messageError && <Typography sx={{ color: 'red', marginTop: 2 }}>{messageError}</Typography>}
        </Box>
      </Modal>
    </React.Fragment>
  )
}

const styles = {
  box: {
    height: 551,
    width: 768, //ШИРИНА НЕ АДАПТИВНАЯ, нужно  донастроить!
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxHeight: 69,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    pt: 8,
    px: 8,
    pb: 4,
    borderRadius: 6,
  },
  papertext: {
    fontWeight: 800,
    fontSize: 50,
    lineHeight: '110%',
    // font-size: 50px,
    // line-height: 110%,
  },
  inputs: {
    mt: 6,
    mb: 6,
  },
}
