import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { DrillsType, UsersType } from '../../types/types'
import { DrillsAPI } from '../../api/drills'
import { GroupAPI } from '../../api/group'
import { setUsersSelectGroup } from './groups_reducer'
import { setUserError } from './users_reducer'
import { setError, setErrorType } from './error_reducer'
const SET_DRILLS = 'SET_DRILLS'
const SET_DRILL = 'SET_DRILL'
const SET_PARTICIPANTS_TEAM = 'SET_PARTICIPANTS_TEAM'
const SET_PARTICIPANTS_TEAM_NULL = 'SET_PARTICIPANTS_TEAM_NULL'

const initialState = {
  drill: {} as DrillsType,
  drills: [] as Array<DrillsType>,
  participantsSelectTeam: [] as Array<any>,
}

const drills_reducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SET_DRILL: {
      return { ...state, drill: action.drill }
    }
    case SET_DRILLS: {
      return { ...state, drills: action.drills }
    }
    case SET_PARTICIPANTS_TEAM: {
      return { ...state, participantsSelectTeam: action.participants }
    }
    case SET_PARTICIPANTS_TEAM_NULL: {
      return { ...state, participantsSelectTeam: [] }
    }
    default:
      return state
  }
}

export default drills_reducer

export const addDrill =
  (name: string, start: number, end: number, withAgreement: boolean, expert: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await DrillsAPI.addDrills(name, [''], start, end, withAgreement, expert)
      if (response.id) {
        const drillsData = await DrillsAPI.getDrills()
        dispatch(setDrills(drillsData))
        dispatch(setError('Тренировка успешно создана', true))
      }
    } catch (error) {
      const apiError = error as { response?: { data?: { message?: string } } }
      if (apiError.response && apiError.response.data) {
        const apiErrorMessage = apiError.response.data.message || 'Request failed'
        dispatch(setError(apiErrorMessage, false))
      } else {
        dispatch(setError('Request failed', false))
      }
    }
  }

export type SetDrillsType = {
  type: typeof SET_DRILLS
  drills: Array<DrillsType>
}

export type SetDrillType = {
  type: typeof SET_DRILL
  drill: DrillsType
}

type ActionsType =
  | SetDrillType
  | SetDrillsType
  | setParticipantsSelectTeamType
  | setParticipantsSelectTeamNullType
  | setErrorType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

export const setDrills = (drills: Array<DrillsType>): SetDrillsType => {
  return {
    type: SET_DRILLS,
    drills,
  }
}

export const setDrill = (drill: DrillsType): SetDrillType => {
  return {
    type: SET_DRILL,
    drill,
  }
}

export const getDrills = (): ThunkType => async (dispatch: DispatchType) => {
  const drillsData = await DrillsAPI.getDrills()
  dispatch(setDrills(drillsData))
}

export const getDrill =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const drillData: any = await DrillsAPI.getDrill(drillId)
    dispatch(setDrills(drillData))
  }

export const addDrillGroup =
  (name: string, start: number, end: number, withAgreement: boolean, groupId: string, expert: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.addDrillsGroup(name, [''], start, end, withAgreement, groupId, expert)
    try {
      const drillsData = await DrillsAPI.getDrills()
      if (response.id) {
        const drillsData = await DrillsAPI.getDrills()
        dispatch(setDrills(drillsData))
        dispatch(setError('Тренировка успешно создана', true))
      }
    } catch (error) {
      const apiError = error as { response?: { data?: { message?: string } } }
      if (apiError.response && apiError.response.data) {
        const apiErrorMessage = apiError.response.data.message || 'Request failed'
        dispatch(setError(apiErrorMessage, false))
      } else {
        dispatch(setError('Request failed', false))
      }
    }
  }

export const addDrillTeam =
  (name: string, start: number, end: number, withAgreement: boolean, teamId: string, expert: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await DrillsAPI.addDrillsTeam(name, start, end, withAgreement, teamId, expert)
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    } catch (error) {
      dispatch(setError('Ошибка создания тренировки', false))
    }
  }

export const editDrill =
  (id: string, name: string, start: number, end: number, withAgreement: boolean, expert: boolean): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      await DrillsAPI.editDrills(id, name, start, end, withAgreement)
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
    } catch (error) {
      console.error('Ошибка при редактировании тренировки:', error)
    }
  }

export const removeDrill =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      const response = await DrillsAPI.deleteDrills(drillId)
      const drillsData = await DrillsAPI.getDrills()
      dispatch(setDrills(drillsData))
      dispatch(setError('Тренировка удалена', true))
    } catch (error) {
      console.error('Ошибка при удалении тренировки:', error)
      dispatch(setError('Ошибка удаленения тренировки', false))
    }
  }

type setParticipantsSelectTeamType = {
  type: typeof SET_PARTICIPANTS_TEAM
  participants: Array<any>
  total: number
}

type setParticipantsSelectTeamNullType = {
  type: typeof SET_PARTICIPANTS_TEAM_NULL
  participants: []
}

export const setParticipantsSelectTeam = (participants: Array<any>, total: number): setParticipantsSelectTeamType => {
  return {
    type: SET_PARTICIPANTS_TEAM,
    participants,
    total,
  }
}

export const nullParticipantsSelectTeam = (participants: any): setParticipantsSelectTeamNullType => {
  return {
    type: SET_PARTICIPANTS_TEAM_NULL,
    participants,
  }
}

export const getParticipants =
  (drillId: string, page?: number, text?: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let participantsTeam = await DrillsAPI.getParticipants(drillId, page, text)
    dispatch(setParticipantsSelectTeam(participantsTeam.rows, participantsTeam.total))
  }

export const nullParticipants =
  (drillId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let participants: any = []
    dispatch(nullParticipantsSelectTeam(participants))
  }

export const setParticipants =
  (userRoles: Array<any>, id: string): ThunkType =>
  async (dispatch: DispatchType) => {
    const response = await DrillsAPI.setParticipants(userRoles, id)
  }
