import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as routes from './constants/routes'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import './index.css'
import store from './redux/store'
import { Provider } from 'react-redux'
import { LanguageProvider } from '.././src/constants/lang'
import SettingsPage from './pages/settings-page'
import { SnackbarProvider } from 'notistack'

const MainPage = lazy(() => import('./pages/main-page'))
const UsersPage = lazy(() => import('./pages/users-page'))
const RightsPage = lazy(() => import('./pages/rights-page/rights-page'))
const RolesPage = lazy(() => import('./pages/roles-page'))
const GroupsPage = lazy(() => import('./pages/group-page/groups-page'))
const FAQPage = lazy(() => import('./pages/faq-page'))
const SETTINGS = lazy(() => import('./pages/settings-page'))
const Error404 = lazy(() => import('./pages/404'))

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <LanguageProvider>
          <App>
            <Suspense fallback={false}>
              <Routes>
                <Route path={routes.MAIN} element={<MainPage />} />
                <Route path={routes.DRILL} element={<MainPage />} />
                <Route path={routes.GENERAL} element={<MainPage />} />
                <Route path={routes.JOURNAL} element={<MainPage />} />
                <Route path={routes.USERS} element={<UsersPage />} />
                <Route path={routes.RIGHTS} element={<RightsPage />} />
                <Route path={routes.ROLES} element={<RolesPage />} />
                <Route path={routes.GROUPS} element={<GroupsPage />} />
                <Route path={routes.FAQ} element={<FAQPage />} />
                <Route path={routes.SETTINGS} element={<SettingsPage />} />
                <Route path={routes.ALL} element={<Error404 />} />
              </Routes>
            </Suspense>
          </App>
        </LanguageProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
