import { instance } from './instance'

export enum ResultCode {
  Success = 0,
  Error = 1,
  CaptchaIsRequired = 10,
}

type MeResponseDataType = {
  id: number
  email: string
  login: string
}

type LoginResponseDataType = {
  url: string
}

type ResponseType<D = {}, RC = ResultCode> = {
  data: D
  result: RC
  messages: Array<string>
}

type ResponseLoginType = {
  message: string
  result: string
}

type reponseUpdPassword = {
  currentPassword: string
  newPassword: string
}

export const authAPI = {
  me() {
    return instance.get<ResponseType<MeResponseDataType>>(`auth/me`).then((res) => res.data)
  },
  login(username: string, password: string, lang: 'RU' | 'EN') {
    return instance
      .post<ResponseLoginType>(`/api/process-login-attempt`, { username, password, lang })
      .then((res) => res.data)
  },
  restore(email: string) {
    return instance.post<any>(`/api/process-restore-attempt`, { email }).then((res) => res.data)
  },
  logout() {
    return instance.post<ResponseLoginType>(`/api/process-logout`).then((res) => res.data)
  },
  updatePassword(passwords: reponseUpdPassword) {
    return instance.post<ResponseLoginType>(`/api/ch-p`, passwords).then((res) => res.data)
  },
}

type securityAPIType = {
  url: string
}

export const securityAPI = {
  getCaptchaUrl() {
    return instance.get<securityAPIType>(`security/get-captcha-url`)
  },
}
